import axios from "@/config/axios";
import oauthAxios from "axios";
// import Swal from "sweetalert2";

export const postAPICall = async (
  method,
  endPoint,
  params = {},
  thirdParty = false,
  responseType = null
) => {
  let options = {
    method: method,
    url: endPoint,
  };

  if (method === "GET") {
    options.params = params;
  } else {
    options.data = params;
  }
  if (responseType) {
    options.responseType = responseType;
  }

  if (thirdParty) {
    const response = await oauthAxios(options);
    return response.data;
  }
  const response = await axios(options);
  return response.data;
};
