// initial state
import axios from "@/config/axios";
import * as  privateAxios from "axios"
import appConfig from "@/config/app";
const initiateState = () => {
  return {
    presignedURLGenerationStatus: false,
    presignedURLsData: {},
    presignedURLsForCompletedDocsData: {},
    blankFileCopyData: {},
    blankFileCopyStatus: false,
    presignedURLsForExistedDocsData: {},
    downloadURLsData: {},
    documentExpiryTokenVerified: false,
    requestNewDocumentLinkStatus: false,
    requestNewDocumentLinkEmail: null,
    removePdfFieldsStatus: false
  }
};
const state = initiateState()
const getters = {
  getPresignedURLGenerationStatus: state => state.presignedURLGenerationStatus,
  getPresignedURLsData: state => state.presignedURLsData,
  getPresignedURLsForCompletedDocsData: state => state.presignedURLsForCompletedDocsData,


  getBlankFileCopyData: state => state.blankFileCopyData,
  getBlankFileCopyStatus: state => state.blankFileCopyStatus,
  getPresignedURLsForExistedDocsData: state => state.presignedURLsForExistedDocsData,
  getDownloadURLsData: state => state.downloadURLsData,
  getDocumentExpiryTokenVerified: state => state.documentExpiryTokenVerified,
  getRequestNewDocumentLinkStatus: state => state.requestNewDocumentLinkStatus,
  getRequestNewDocumentLinkEmail: state => state.requestNewDocumentLinkEmail,
  getRemovePdfFieldsStatus: state => state.removePdfFieldsStatus

};

const mutations = {

  resetData: state => {
    const initial = initiateState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
  setPresignedURLGenerationStatus: (state, value) => {
    state.presignedURLGenerationStatus = value
  },

  setPresignedURLsData: (state, value) => {
    state.presignedURLsData = value
  },
  setPresignedURLsForCompletedDocsData: (state, value) => {
    state.presignedURLsForCompletedDocsData = value
  },
  setBlankFileCopyData: (state, value) => {
    state.blankFileCopyData = value
  },
  setBlankFileCopyStatus: (state, value) => {
    state.blankFileCopyStatus = value
  },
  setPresignedURLsForExistedDocsData: (state, value) => {
    state.presignedURLsForExistedDocsData = value
  },
  setDownloadURLsData: (state, value) => {
    state.downloadURLsData = value
  },
  setDocumentExpiryTokenVerified: (state, value) => {
    state.documentExpiryTokenVerified = value
  },
  setRequestNewDocumentLinkStatus: (state, value) => {
    state.requestNewDocumentLinkStatus = value
  },
  setRequestNewDocumentLinkEmail: (state, value) => {
    state.requestNewDocumentLinkEmail = value
  },
  setRemovePdfFieldsStatus: (state, status) => {
    state.removePdfFieldsStatus = status
  }
};
const actions = {
  reset: function (context) {
    context.commit("documentOperations/resetData", null, {
      root: true
    });
  },

  getPresignedURLForDocs: async (context, params) => {
    try {

      let response = {}
      if (params.attachments) {
        response = await axios.post("/documents-templates/requested-files", params)
      }
      else {
        response = await axios.post("/documents-templates/processed-files", params)
      }

      context.commit("documentOperations/setPresignedURLsData", response.data.data, {
        root: true
      });
      context.commit("documentOperations/setPresignedURLGenerationStatus", true, {
        root: true
      });
    }

    catch (err) {
      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });
    }
  },

  getPresignedURLForCompletedDocs: async (context, params) => {
    try {

      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });

      const response = await axios.post("/documents-templates/completed-documents", params)


      context.commit("documentOperations/setPresignedURLsForCompletedDocsData", response.data.data, {
        root: true
      });
      context.commit("documentOperations/setPresignedURLGenerationStatus", true, {
        root: true
      });
    }

    catch (err) {
      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });
    }
  },
  getPresignedURLForExistedDocs: async (context, params) => {
    try {

      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });

      const response = await axios.post("/documents-templates/existed-documents", params)

      context.commit("documentOperations/setPresignedURLsForExistedDocsData", response.data.data, {
        root: true
      });
      context.commit("documentOperations/setPresignedURLGenerationStatus", true, {
        root: true
      });
    }

    catch (err) {
      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });
    }
  },


  getBlackFileCopyS3URL: async (context, params) => {
    try {

      context.commit("documentOperations/setBlankFileCopyStatus", false, {
        root: true
      });

      const response = await axios.post("/documents-templates/copy-blank-documents", params)


      context.commit("documentOperations/setBlankFileCopyData", response.data.data, {
        root: true
      });
      context.commit("documentOperations/setBlankFileCopyStatus", true, {
        root: true
      });
    }

    catch (err) {
      context.commit("documentOperations/setBlankFileCopyStatus", false, {
        root: true
      });
    }
  },

  fetchDownloadURLsData: async (context, params) => {
    try {

      context.commit("documentOperations/setDownloadURLsData", [], {
        root: true
      });

      const response = await axios.post("/documents-templates/download-urls", params)


      context.commit("documentOperations/setDownloadURLsData", response.data.data, {
        root: true
      });
      context.commit("documentOperations/setBlankFileCopyStatus", true, {
        root: true
      });
    }

    catch (err) {
      context.commit("documentOperations/setBlankFileCopyStatus", false, {
        root: true
      });
    }
  },





  uploadDocumentsToS3: async (context, params) => {
    try {
      const urlAxios = privateAxios.create({});

      await urlAxios.put(params.url, params.file)

      return true;
    }
    catch (err) {
      console.log({ err })
      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });

      return false;
    }
  },

  mergeDocuments: async (context, params) => {
    try {
      let url = `${appConfig.JAVA_BASE_URL}/rest/esignaturev5/mergeFiles`;
      const urlAxios = privateAxios.create({});
      let response = await urlAxios.post(url, params)
      console.log({ response })

      return true;
    }
    catch (err) {
      console.log({ err })
      context.commit("documentOperations/setPresignedURLGenerationStatus", false, {
        root: true
      });

      return false;
    }
  },
  //upload single file
  uploadFilesToConvertv1: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadConvertFilesStatus", false, {
        root: true
      });
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + "/rest/esignature/convertFiles", {
        method: 'POST',
        body: params
      })
      const data = await response.json();
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  //V4 API's using from frontend
  uploadFilesToConvertv4: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadConvertFilesStatus", false, {
        root: true
      });
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      let uploadUrl = `${appConfig.JAVA_BASE_URL}/rest/esignaturev4/convertFiles?company_id=${process.env.VUE_APP_APP_TYPE}/${params.companyId}`;
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: params.data
      });
      const data = await response.json();
      const dt = data.fileInfos;
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  uploadUserDocxFilev4: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      let uploadUrl = `${appConfig.JAVA_BASE_URL}/rest/esignaturev4/mergeFiles?company_id=${process.env.VUE_APP_APP_TYPE}/${params.companyId}`;
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: params.data
      });
      const data = await response.json();
      const dt = data.fileInfos[0];
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  addPageToDocumentv5: async (context, params) => {
    try {
      context.commit("fileUpload/setAddPageStatus", false, {
        root: true
      });
      context.commit("fileUpload/setAddPageData", null, {
        root: true
      });
      console.log(params)
      await fetch(appConfig.JAVA_BASE_URL + "/rest/esignaturev5/addNewPage", {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      context.commit("fileUpload/setAddPageStatus", true, {
        root: true
      });
      // const data = await response.json();
      // const dt = data.fileInfos[0]
      // context.commit("fileUpload/setAddPageData", dt, { root: true });
    } catch (error) {
      console.log(error)
    }
  },
  fileTheUploadv5: async (context, params) => {
    try {
      //signed pdf download api
      context.commit("fileUpload/setUploadStatus", null, {
        root: true
      })

      const response = await fetch(appConfig.JAVA_BASE_URL + "/rest/esignaturev5/edit", {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const res = await response.json();
      console.log(res)
      // const data = res.data
      context.commit("fileUpload/setUploadStatus", res, {
        root: true
      })
    } catch (error) {
      console.log(error)
    }
  },
  duplicateUploadedDocumentv5: async (context, params) => {
    try {
      context.commit("fileUpload/setCopyDocumentUrl", null, {
        root: true
      });
      context.commit("fileUpload/setCopyDocumentStatus", false, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + `/rest/esignaturev5/duplicateFile?`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      const dt = data.fileInfos[0];
      context.commit("fileUpload/setCopyDocumentUrl", dt, {
        root: true
      });
      context.commit("fileUpload/setCopyDocumentStatus", true, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },



  uploadFilesToConvertv5: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadConvertFilesStatus", false, {
        root: true
      });

      let uploadUrl = `${appConfig.JAVA_BASE_URL}/rest/esignaturev5/convertFiles`;
      await fetch(uploadUrl, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      context.commit("fileUpload/setUploadConvertFilesStatus", true, {
        root: true
      });
    } catch (error) {
      context.commit("fileUpload/setUploadConvertFilesStatus", false, {
        root: true
      });
      console.log(error)
    }
  },


  uploadFilesToConvert: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadConvertFilesStatus", false, {
        root: true
      });

      // const response = await fetch(appConfig.JAVA_BASE_URL+"/rest/esignature/convertFiles", {method: 'POST', body:params})
      const response = await axios.post(`/documents/attachFiles`, params)
      // const data = await response.data.json();
      const data = response.data.data;
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  getCopiedDocument: async (context, id) => {
    try {
      context.commit("fileUpload/setCopyDocumentUrl", null, {
        root: true
      });
      context.commit("fileUpload/setCopyDocumentStatus", false, {
        root: true
      });
      // const response = await fetch(appConfig.JAVA_BASE_URL+`/rest/esignature/duplicateFile?fileName=${id}`, {method: 'POST'});
      const response = await axios.post(`/documents/duplicateFile/` + id)
      // const data = await response.data.json();
      const data = response.data.data;
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setCopyDocumentUrl", dt, {
        root: true
      });
      context.commit("fileUpload/setCopyDocumentStatus", true, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  uploadUserFile: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + "/rest/esignature/files", params)
      const data = await response.json();
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  uploadUserDocxFilev1: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + "/rest/esignature/mergeFiles", {
        method: 'POST',
        body: params
      });
      const data = await response.json();
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  fetchUploadedDocumentv1: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadedPdfData", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + `/rest/esignature/retrieveUnsignedFile?fileName=${params}`, {
        method: 'GET'
      });
      const data = await response.json();
      context.commit("fileUpload/setUploadedPdfData", data, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  uploadUserDocxFile: async (context, params) => {
    try {
      context.commit("fileUpload/setUploadFileDataStatus", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + "/rest/esignature/mergeFiles", {
        method: 'POST',
        body: params
      });
      const data = await response.json();
      const dt = data.fileInfos[0]
      context.commit("fileUpload/setUploadFileDataStatus", dt, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  fileTheUploadv1: async (context, params) => {
    try {
      //signed pdf download api
      context.commit("fileUpload/setUploadStatus", null, {
        root: true
      })
      console.log(params)
      // const response = await fetch (appConfig.JAVA_BASE_URL+"/rest/esignature/edit", {method: 'POST', body:JSON.stringify(params),headers: {
      //   'Content-Type': 'application/json'
      // }})
      const response = await axios.post(`/documents/editFile`, params)
      // const data = await response.data.json();
      const data = response.data.data;
      const dt = data.fileInfos[0]
      // const data = res.data
      context.commit("fileUpload/setUploadStatus", dt, {
        root: true
      })
    } catch (error) {
      console.log(error)
    }
  },
  fileTheUpload: async (context, params) => {
    try {
      //signed pdf download api
      context.commit("fileUpload/setUploadStatus", null, {
        root: true
      })
      console.log(params)
      // const response = await fetch (appConfig.JAVA_BASE_URL+"/rest/esignature/edit", {method: 'POST', body:JSON.stringify(params),headers: {
      //   'Content-Type': 'application/json'
      // }})
      const response = await axios.post(`/documents/editFile`, params)
      // const res = await response.data.json();
      const res = response.data.data;
      console.log(res)
      // const data = res.data
      context.commit("fileUpload/setUploadStatus", res, {
        root: true
      })
    } catch (error) {
      console.log(error)
    }
  },
  fetchFontTypes: async (context) => {
    try {
      const config = {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
      };
      //getting font styles
      context.commit("fileUpload/setFontTypes", null, {
        root: true
      });
      const response = await fetch(appConfig.JAVA_BASE_URL + '/rest/esignature/getFonts', config);
      const data = await response.json();
      context.commit("fileUpload/setFontTypes", data, {
        root: true
      });
    } catch (error) {
      console.log(error)
    }
  },
  verifyDocumentExpiryToken: async (context, params) => {
    try {

      context.commit("documentOperations/setDocumentExpiryTokenVerified", false, {
        root: true
      });
      await axios.post(`/documents/${params.id}/document-users/verify-expiry-token`, params);
      context.commit("documentOperations/setDocumentExpiryTokenVerified", true, {
        root: true
      });

    } catch (err) {
      context.commit("documentOperations/setDocumentExpiryTokenVerified", false, {
        root: true
      });
      console.log(err)
    }
  },

  requestNewDocumentLink: async (context, params) => {
    try {

      context.commit("documentOperations/setRequestNewDocumentLinkStatus", false, {
        root: true
      });

      let resp = await axios.post(`/documents/${params.id}/document-users/resend-document-expiry-link`, params);

      context.commit("documentOperations/setRequestNewDocumentLinkStatus", true, {
        root: true
      });

      context.commit("documentOperations/setRequestNewDocumentLinkEmail", resp.data.data.email, {
        root: true
      });


    } catch (err) {
      context.commit("documentOperations/setRequestNewDocumentLinkStatus", false, {
        root: true
      });
      context.commit("documentOperations/setRequestNewDocumentLinkEmail", null, {
        root: true
      });

      console.log(err)
    }
  },

  removePdfFields: async (context, params) => {
    try {
 
      context.commit("documentOperations/setRemovePdfFieldsStatus", false, {
        root: true
      });
 
      await axios.post(`/documents/files/remove-fields`, params);
 
      context.commit("documentOperations/setRemovePdfFieldsStatus", true, {
        root: true
      });
 
    } catch (err) {
      context.commit("documentOperations/setRemovePdfFieldsStatus", false, {
        root: true
      });
      console.log(err)
    }
  }


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};