import axios from "@/config/axios";

const initialState = () => {
  return {
    createNewDashboard: null,
    allDashboards: [],
    deleteDashboardStatus: false,
    updateDashboardStatus: false,
    updateDashboardData: null,
    allEntityFilters: null,
    singleDashboardData: null,
    userDashboardsList: null,
    createNewDashboardError: null,
    updateDashboardError: null,
    entityCustomDashboard: null,
    entityUserDashboardsList: null,
    customDashboardTableWidth: null
  };
};
const state = initialState();

const getters = {
  getCreateNewDashboard: (state) => state.createNewDashboard,
  getAllDashboards: (state) => state.allDashboards,
  getDeleteDashboardStatus: (state) => state.deleteDashboardStatus,
  getUpdateDashboardStatus: (state) => state.updateDashboardStatus,
  getUpdateDashboardData: state => state.updateDashboardData,
  getAllEntityFilters: (state) => state.allEntityFilters,
  getSingleDashboardData: (state) => state.singleDashboardData,
  getUserDashboardsList: (state) => state.userDashboardsList,
  getCreateNewDashboardError: (state) => state.createNewDashboardError,
  getUpdateDashboardError: (state) => state.updateDashboardError,
  getEntityCustomDashboard: state => state.entityCustomDashboard,
  getEntityUserDashboardsList: state => state.entityUserDashboardsList,
  getCustomDashboardTableWidth: state => state.customDashboardTableWidth
};
const mutations = {
  setCreateNewDashboard: (state, data) => {
    state.createNewDashboard = data;
  },
  setAllDashboards: (state, data) => {
    state.allDashboards = data
  },
  setDeleteDashboardStatus: (state, status) => {
    state.deleteDashboardStatus = status;
  },
  setUpdateDashboardStatus: (state, status) => {
    state.updateDashboardStatus = status
  },
  setUpdateDashboardData: (state, data) => {
    state.updateDashboardData = data
  },
  setAllEntityFilters: (state, data) => {
    state.allEntityFilters = data
  },
  setSingleDashboardData: (state, data) => {
    state.singleDashboardData = data
  },
  setUserDashboardsList: (state, data) => {
    state.userDashboardsList = data
  },
  setCreateNewDashboardError: (state, error) => {
    state.createNewDashboardError = error
  },
  setUpdateDashboardError: (state, error) => {
    state.updateDashboardError = error
  },
  setEntityCustomDashboard: (state, data) => {
    state.entityCustomDashboard = data
  },
  setEntityUserDashboardsList: (state, data) => {
    state.entityUserDashboardsList = data
  },
  setCustomDashboardTableWidth: (state, data) => {
    state.customDashboardTableWidth = data
  }
};
const actions = {
  fetchCreateNewDashboard: async ({ commit }, params) => {
    try {
      commit("setCreateNewDashboard", null);
      commit("setCreateNewDashboardError", null);
      const response = await axios.post("custom-dashboard/create", params);
      commit("setCreateNewDashboard", response.data.data);
    } catch (err) {
      if (err?.response?.data) {
        console.log("setCreateNewDashboardError", err.response.data);
        commit("setCreateNewDashboardError", err.response.data);
      }
      commit("setCreateNewDashboard", null);
    }
  },

  fecthAllDashboards: async ({ commit }, params) => {
    try {
      commit("setAllDashboards", null);
      const response = await axios.get(`custom-dashboard`, { params });
      commit("setAllDashboards", response.data);
    } catch (err) {
      commit("setAllDashboards", null);
    }
  },

  getDashboardById: async ({ commit }, id) => {
    try {
      commit("setSingleDashboardData", null);
      const response = await axios.get(`custom-dashboard/${id}`);
      commit("setSingleDashboardData", response.data.data);
    } catch (err) {
      commit("setSingleDashboardData", null);
    }
  },
  getDashboardByEntityId: async ({ commit }, id) => {
    try {
      commit("setEntityCustomDashboard", null);
      const response = await axios.get(`ap/custom-dashboard/${id}`);
      commit("setEntityCustomDashboard", response.data.data);
    } catch (err) {
      commit("setEntityCustomDashboard", null);
    }
  },

  updateDashboard: async ({ commit }, data) => {
    try {
      commit("setUpdateDashboardStatus", null);
      commit("setUpdateDashboardData", null);
      commit("setUpdateDashboardError", null);
      const response = await axios.post(`custom-dashboard/${data.id}`, data.params);
      commit("setUpdateDashboardData", response.data);
      commit("setUpdateDashboardStatus", true);
    } catch (err) {
      if (err?.response?.data) {
        commit("setUpdateDashboardError", err.response.data);
      }
      commit("setUpdateDashboardStatus", null);
    }
  },

  fetchEntityUserDashboardsList: async ({ commit }, id) => {
    try {
      commit("setEntityUserDashboardsList", null);
      const response = await axios.get(`ap/custom-dashboard/entity-type/${id}`);
      commit("setEntityUserDashboardsList", response.data.data);
    } catch (err) {
      commit("setEntityUserDashboardsList", null);
    }
  },
  fetchUserDashboardsList: async ({ commit }, id) => {
    try {
      commit("setUserDashboardsList", null);
      const response = await axios.get(`custom-dashboard/user-type/${id}`);
      commit("setUserDashboardsList", response.data.data);
    } catch (err) {
      commit("setUserDashboardsList", null);
    }
  },

  fetchAllEntityFilters: async ({ commit }, params) => {
    try {
      commit("setAllEntityFilters", null);
      const response = await axios.get(`custom-dashboard`, { params });
      commit("setAllEntityFilters", response.data.data);
    } catch (err) {
      commit("setAllEntityFilters", null);
    }
  },
  deleteDashboard: async ({ commit }, id) => {
    try {
      commit("setDeleteDashboardStatus", false);
      await axios.delete(`custom-dashboard/${id}`);
      commit("setDeleteDashboardStatus", true);
    } catch (err) {
      commit("setDeleteDashboardStatus", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
