import axios from "@/config/axios";

const initialState = () => {
  return {
    createNewAnonymousForm: null,
    deleteAnonymousFormStatus: false,
    updateAnonymousFormStatus: false,
    singleAnonymousFormData: null,
    deleteAnonymousErros: null,
  };
};
const state = initialState();

const getters = {
  getCreateNewAnonymousForm: (state) => state.createNewAnonymousForm,
  getDeleteAnonymousFormStatus: (state) => state.deleteAnonymousFormStatus,
  getUpdateAnonymousFormStatus: (state) => state.updateAnonymousFormStatus,
  getSingleAnonymousFormData: (state) => state.singleAnonymousFormData,
  getDeleteAnonymousErros: (state) => state.deleteAnonymousErros,
};
const mutations = {
  setCreateNewAnonymousForm: (state, data) => {
    state.createNewAnonymousForm = data;
  },
  setDeleteAnonymousFormStatus: (state, status) => {
    state.deleteAnonymousFormStatus = status;
  },
  setUpdateAnonymousFormStatus: (state, status) => {
    state.updateAnonymousFormStatus = status
  },
  setSingleAnonymousFormData: (state, data) => {
    state.singleAnonymousFormData = data
  },
  setDeleteAnonymousErros:(state, error) => {
    state.deleteAnonymousErros = error
  },
};
const actions = {
    fetchCreateNewAnonymousForm: async ({ commit }, params) => {
        try {
          commit("setCreateNewAnonymousForm", null);
          const response = await axios.post("anonymous-form", params);
          commit("setCreateNewAnonymousForm", response.data.data);
        } catch (err) {
          commit("setCreateNewAnonymousForm", null);
        }
      },
      getAnonymousFormById: async ({ commit }, id) => {
        try {
          commit("setSingleAnonymousFormData", null);
          const response = await axios.get(`anonymous-form/${id}`);
          commit("setSingleAnonymousFormData", response.data.data);
        } catch (err) {
          commit("setSingleAnonymousFormData", null);
        }
      },
      updateAnonymousForm: async ({ commit }, data) => {
        try {
          commit("setUpdateAnonymousFormStatus", null);
          await axios.post(`anonymous-form/${data.id}`, data.params);
          commit("setUpdateAnonymousFormStatus", true);
        } catch (err) {
          commit("setUpdateAnonymousFormStatus", null);
        }
      },
      deleteAnonymousForm: async ({ commit }, id) => {
        try {
          commit("setDeleteAnonymousFormStatus", false);
          commit("setDeleteAnonymousErros", null);
          await axios.delete(`anonymous-form/${id}`);
          commit("setDeleteAnonymousFormStatus", true);
        } catch (err) {
          commit("setDeleteAnonymousErros", err.response.data);
          commit("setDeleteAnonymousFormStatus", false);
        }
      },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};