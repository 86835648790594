import axios from "@/config/axios";

const initiateState = () => {
    return {
        newApplication: {},
        errors: {},
        // success: {},
        // role:{},

        allApplications:{},
        installationStatus:{},
        allCategories:{},
        appDeletionStatus:{},
        appUninstallStatus:{},
        appDeletionErrors:{},
        appInstallationErrors:{},
        appUpdatedStatus : {},
        appUpdatedErrors : {},
        installedApplications : {},
        installedAppsErrors : {},
        allEntitiesPreviewMappingData : {},
        appImportData : {},
    }
}
const state = initiateState()
const getters = {
    getAppErrors: state => state.errors,
    getAllApps: state => state.allApplications,
    getNewApplicationInfo: state=>state.newApplication,
    getInstallationStatus: state=>state.installationStatus,
    getAppInstallationErrors: state=>state.appInstallationErrors,
    getAppUpdatedStatus: state=>state.appUpdatedStatus,
    getAppUpdatedErrors : state=>state.appUpdatedErrors,
    getAllCategories : state=>state.allCategories,
    getAppDeletionStatus : state=>state.appDeletionStatus,
    getAppUninstallStatus : state => state.appUninstallStatus,
    getAppDeletionErrors : state=>state.appDeletionErrors,
    getInstalledApplications : state=>state.installedApplications,
    getInstalledAppsErrors : state=>state.installedAppsErrors,
    getAllEntitiesPreviewMappingData : state=>state.allEntitiesPreviewMappingData,
    getAppImportData : state=>state.appImportData,
}
const mutations = {
    setAppErrors(state, data) {
        state.errors = data
    },
    setNewApplicationInfo(state, data) {
        state.newApplication = data
    },
    setInstallationStatus(state,data){
        state.installationStatus=data
    },
    setAppInstallationErrors(state,data){
        state.appInstallationErrors=data
    },
    setAppUpdatedStatus(state,data){
        state.appUpdatedStatus = data
    },
    setAppUpdatedErrors(state,data){
        state.appUpdatedErrors=data
    },
    setAppDeletionStatus(state,data){
        state.appDeletionStatus=data
    },
    setAppUninstallStatus(state,data){
        state.appUninstallStatus = data
    },
    setAppDeletionErrors(state,data){
        state.appDeletionErrors=data
    },
    setApplications(state,data){
        state.allApplications=data
    },
    setInstalledApplications(state,data){
        state.installedApplications = data
    },
    setInstalledAppsErrors(state,data){
        state.installedAppsErrors = data
    },
   
    setCategories(state,data){
        state.allCategories = data
    },
    setAllEntitiesPreviewMappingData(state, data){
        state.allEntitiesPreviewMappingData = data
    },
    setAppImportData(state, data){
        state.appImportData = data
    },
    resetData: state => {
        const initial = initiateState();
        Object.keys(initial).forEach(key => {
            state[key] = initial[key];
        });
    },

}
const actions = {
    reset: function (context) {
        context.commit("apps/resetData", null, {
            root: true
        });
    },
    resetErrors: (context) => {
        context.commit("setAppErrors", {}, {
            root: true
        })
    },
    errorResponse: (context, errorResponse) => {
        let errorStatusCode = errorResponse.status;
        let errorData = {};
        switch (errorStatusCode) {
            case 422:
                var errors = errorResponse.data.errors;
                errors.details.forEach(detail => {

                    if (detail.key == "files") {
                        if (!errorData[detail.path]) {
                            errorData[detail.path] = detail.message
                        }
                    } else {
                        if (!errorData[detail.key]) {
                            errorData[detail.key] = detail.message;
                        }
                    }
                });
                break;
            case 401:
            case 403:
                errorData["critical_error"] = errorResponse.data.message;

                break;

            default:
                errorData["critical_error"] = errorResponse.data.message;
                break;
        }
        return errorData;
    },

    fetchAllApplications: async (context, params) => {
        try {
            context.commit("apps/setApplications", 
            {
                data: [],
                total: 0,
                page: 0
            }
            , {
                root: true
            })
            const response = await axios.get('/fetchAllApps', {
                params
            })
            // we will get pagination data so we are storing complete data 
            // console.log(response.data)
            context.commit("apps/setApplications", response.data, {
                root: true
            })
        } catch (errors) {
            context.commit("apps/setApplications",
             {
                data: [],
                total: 0,
                page: 0
            }
            , {
                root: true
            })
        }
    },
    fetchAllInstalledApplications : async (context,params)=>{
        try{
            context.commit("apps/setInstalledApplications",{},{root : true})
            const response = await axios.get('/fetchInstalledApplications',{params});
            context.commit("apps/setInstalledApplications",response.data,{
                root : true
            })
        }
        catch(error){
            context.commit("apps/setInstalledAppsErrors", {}, {root: true })
        }
    },
    fetchAllCategories : async (context)=>{
        try{
            context.commit("apps/setCategories",{},{root : true})
            const response = await axios.get('/get-all-industry-types')
            context.commit("apps/setCategories",response.data,{
                root : true
            })
        }
        catch(error){
            context.commit("apps/setNewApplicationInfo", {}, {root: true })
        }
    },
    createNewApplication:async(context,params)=>{
        try{
            context.commit("apps/setNewApplicationInfo", {}, {root: true })
            context.commit("setAppErrors",{})
            const response = await axios.post('/create-application', 
                params
            )
            // we will get pagination data so we are storing complete data 
            context.commit("apps/setNewApplicationInfo", response.data, {
                root: true
            })
        }
        catch(error)
        {
            context.commit("apps/setNewApplicationInfo", {}, {root: true })
            context.commit("setAppErrors", error.data.message, {
                root: true
            })
        }
    },

    installApplication:async(context,params)=>{
        try{
            context.commit("apps/setInstallationStatus", false, {root: true })
            context.commit("setAppInstallationErrors",null,)
            const response = await axios.post('/createApp/'+params._id,params
            )
            // we will get pagination data so we are storing complete data 
            context.commit("apps/setInstallationStatus", response.data, {
                root: true
            })
        }
        catch(error)
        {
            context.commit("apps/setInstallationStatus", false, {root: true })
            console.log("eroorrrrr",error.response.data.message)
            if (error.response && error.response.data) {
                context.commit("setAppInstallationErrors", error.response.data.message);
              }
        }
    },
    updateApplication : async (context,params)=>{
        try{
            context.commit("apps/setAppUpdatedStatus", false, {root: true })
            context.commit("setAppUpdatedErrors",{})
            const response = await axios.patch('/updatePublishedApp',params
            )
            // we will get pagination data so we are storing complete data 
            context.commit("apps/setAppUpdatedStatus", response.data, {
                root: true
            })
        }
        catch(error)
        {
            context.commit("apps/setAppUpdatedStatus", false, {root: true })
            if (error.response && error.response.data) {
                context.commit("setAppUpdatedErrors", error.response.data.message);
              }
        }
    },

    deleteApplication: async(context,params)=>{
        try{
            context.commit("apps/setAppDeletionStatus",false, {root: true })
            context.commit("setAppDeletionErrors",{})
            const response = await axios.delete ('/delete-application-permanently/'+params._id,params
            )
            context.commit("apps/setAppDeletionStatus", response.data, {
                root: true
            })
        }
        catch(error){
            context.commit("apps/setAppDeletionStatus",false, {root: true })
            if (error.response && error.response.data) {
                context.commit("setAppDeletionErrors", error.response.data.message);
              }
        }
    },
    uninstallApplication: async(context,params)=>{
        try{
            context.commit("apps/setAppUninstallStatus",false, {root: true })
            context.commit("setAppDeletionErrors",{})
            const response = await axios.delete ('/uninstallApplication/'+params._id
            )
            context.commit("apps/setAppUninstallStatus", response.data, {
                root: true
            })
        }
        catch(error){
            context.commit("apps/setAppUninstallStatus",false, {root: true })
            if (error.response && error.response.data) {
                context.commit("setAppDeletionErrors", error.response.data.message);
              }
        }
    },
    createAndFetchDataForPreviewScreenInExcelApp : async(context, params)=>{
        try{
            context.commit("apps/setAllEntitiesPreviewMappingData",false, {root : true})
            const response = await axios.post('/create-entities-templates',params)
            context.commit("apps/setAllEntitiesPreviewMappingData",response.data.data,{root:true})
        }
        catch(err){
            console.log(err)
        }
    },
    importApplicationDataForExcel : async(context, params)=>{
        try{
            context.commit('apps/setAppImportData',false,{root : true})
            const response = await axios.post('/create-entities-templates',params)
            context.commit('apps/setAppImportData',response,{root : true})
        }
        catch(err){
            console.log(err)
        }
    }
}
export default {
    namespaced: true,
    mutations,
    getters,
    state,
    actions
}