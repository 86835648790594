import axios from "@/config/axios";

const initiateState = () => {
    return {
        allAppNotifications : {},
        appNotificationErrors : {},
        // unreadNotificationsCount : null,
        markAllAsReadStatus : false,
        // totalNotificationsCount : null,
        updateNotificationStatus : false,
        // allNotificationsCount : null,
        // totalUnreadNotificationsCount : null,
    }
}

const state = initiateState();
const getters = {
    getAllAppNotifications : state =>  state.allAppNotifications,
    getAppNotificationErrors : state =>  state.appNotificationErrors,
    // getUnreadNotificationsCount : state => state.unreadNotificationsCount,
    getMarkAllAsReadStatus : state => state.markAllAsReadStatus,
    getUpdateNotificationStatus : state => state.updateNotificationStatus,
    // getTotalNotificationsCount : state => state.totalNotificationsCount,
    // getAllNotificationsCount : state => state.allNotificationsCount,
    // getTotalUnreadNotificationsCount : state => state.totalUnreadNotificationsCount,
} 

const mutations = {
    setAllAppNotifications(state, data) {
        state.allAppNotifications = data;
    },
    setAppNotificationErrors(state, data) {
        state.appNotificationErrors = data;
    },
    // setUnreadNotificationsCount(state, data) {
    //     state.unreadNotificationsCount = data;
    // },
    setMarkAllAsReadStatus(state, data) {
        state.markAllAsReadStatus = data;
    },
    setUpdateNotificationStatus(state, data) {
        state.updateNotificationStatus = data;
    },
    // setTotalNotificationsCount(state, data) {
    //     state.totalNotificationsCount = data;
    // },
    // setAllNotificationsCount(state, data) {
    //     state.allNotificationsCount = data;
    // },
    // setTotalUnreadNotificationsCount(state, data) {
    //     state.totalUnreadNotificationsCount = data
    // }
}

const actions = {
    fetchAllAppNotifications : async (context,params)=>{
        try{
            context.commit("appNotifications/setAllAppNotifications",null,{root : true});
            // context.commit("appNotifications/setUnreadNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setTotalNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setAllNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setTotalUnreadNotificationsCount",null,{root : true});
            let url = `app-notifications/fetchAllAppNotifications`;
            const response = await axios.get(url,{params});
            context.commit("appNotifications/setAllAppNotifications",response.data,{
                root : true
            });
            // context.commit("appNotifications/setUnreadNotificationsCount",response.data.unread_count,{root : true});
            // context.commit("appNotifications/setTotalNotificationsCount",response.data.total_notifications_count,{root : true});
            // context.commit("appNotifications/setAllNotificationsCount",response.data.total_count,{root : true});
            // context.commit("appNotifications/setTotalUnreadNotificationsCount",response.data.total_unread_count,{root : true});
        }
        catch(error){
            context.commit("appNotifications/setAllAppNotifications", null, {root: true });
            // context.commit("appNotifications/setUnreadNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setTotalNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setAllNotificationsCount",null,{root : true});
            // context.commit("appNotifications/setTotalUnreadNotificationsCount",null,{root : true});
            context.commit("appNotifications/setAppNotificationErrors", error, {
                root: true
            })
        }
    },
    fetchUnreadCount : async (context) => {
        try{
            context.commit("appNotifications/setUnreadNotificationsCount",null,{root : true})
            const response = await axios.get('app-notifications/fetchUnreadNotificationsCount');
            context.commit("appNotifications/setUnreadNotificationsCount",response.data.data,{
                root : true
            })
        }
        catch(error) {
            context.commit("appNotifications/setUnreadNotificationsCount", null, {root: true })
            context.commit("appNotifications/setAppNotificationErrors", error, {
                root: true
            })
        }
    },
    markAllAsRead : async (context,params) => {
        try{
            context.commit("appNotifications/setMarkAllAsReadStatus",false,{root : true})
            const response = await axios.get(`app-notifications/markAllNotificationsAsRead?type=${params.type}`);
            context.commit("appNotifications/setMarkAllAsReadStatus",response.data.success,{
                root : true
            })
        }
        catch(error) {
            context.commit("appNotifications/setMarkAllAsReadStatus", false, {root: true })
            context.commit("appNotifications/setAppNotificationErrors", error.data.message, {
                root: true
            })
        }
    },
    updateDataById : async (context,params) => {
        try {
            context.commit("appNotifications/setUpdateNotificationStatus",false,{root : true})
            const response = await axios.post(`app-notifications/update_data/${params.id}`,params.data);
            context.commit("appNotifications/setUpdateNotificationStatus",response.data.success,{
                root : true
            })
        }
        catch(error) {
            context.commit("appNotifications/setUpdateNotificationStatus", false, {root: true })
            context.commit("appNotifications/setUpdateNotificationStatus", error.data.message, {
                root: true
            })
        }
    }
}
export default {
    namespaced: true,
    mutations,
    getters,
    state,
    actions
}