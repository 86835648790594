import axios from "@/config/axios";

const initialState = () => {
  return {
    createNewPipeline: null,
    entityActions: null,
    updatePipelineStatus: null,
    deletePipelineStatus: false
  };
};
const state = initialState();

const getters = {
  getCreateNewPipeline: (state) => state.createNewPipeline,
  getEntityActions: (state) => state.entityActions,
  getUpdatePipelineStatus: (state) => state.updatePipelineStatus,
  getDeletePipelineStatus: (state) => state.deletePipelineStatus
};
const mutations = {
  setCreateNewPipeline: (state, data) => {
    state.createNewPipeline = data;
  },
  setEntityActions: (state, data) => {
    state.entityActions = data;
  },
  setUpdatePipelineStatus: (state, data) => {
    state.updatePipelineStatus = data;
  },
  setDeletePipelineStatus: (state, data) => {
    state.deletePipelineStatus = data
  }
};
const URL = "automation-pipeline";
const actions = {
  createNewPipeline: async ({ commit }, params) => {
    try {
      commit("setCreateNewPipeline", null);
      const response = await axios.post(URL + "/create", params);
      commit("setCreateNewPipeline", response.data.data);
    } catch (err) {
      commit("setCreateNewPipeline", null);
    }
  },
  getEntityActions: async ({ commit }, params) => {
    try {
      commit("setEntityActions", null);
      const response = await axios.get(URL + "/entity-actions", { params });
      commit("setEntityActions", response.data);
    } catch (err) {
      commit("setEntityActions", null);
    }
  },
  updatePipeline: async ({ commit }, params) => {
    try {
      commit("setUpdatePipelineStatus", false);
      await axios.patch(URL + "/update/"+params._id, params);
      commit("setUpdatePipelineStatus", true);
    } catch (err) {
      commit("setUpdatePipelineStatus", false);
    }
  },
  deletePipeline: async ({ commit }, id) => {
    try {
      commit("setDeletePipelineStatus", false);
      await axios.delete(URL + "/delete/"+id);
      commit("setDeletePipelineStatus", true);
    } catch (err) {
      commit("setDeletePipelineStatus", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
