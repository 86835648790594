import axios from "@/config/axios";

const initialState = () => {
    return {
        smtpConfiguration: {},
        smtpStatus: null,
        error: {}
    };
};
const state = initialState();

const getters = {
    getSmtpConfiguration: (state) => state.smtpConfiguration,
    getSmtpStatus: (state) => state.smtpStatus,
    getError: (state) => state.error
};
const mutations = {
    setSmtpConfiguration: (state, data) => {
        state.smtpConfiguration = data;
    },
    setSmtpStatus: (state, status) => {
        state.smtpStatus = status;
    },
    setError: (state, error) => {
        state.error = error;
    }
};
const actions = {
    addSMTP: async ({ commit }, params) => {
        try {
            commit("setError", {});
            commit("setSmtpStatus", null);
            commit("setSmtpConfiguration", {});
            const response = await axios.post("/config/smtp", params);
            commit("setSmtpConfiguration", response.data.data);
            commit("setSmtpStatus", true);
        } catch (error) {
            console.log(error);
            commit("setError", {message: error&&error.response&&error.response.data && error.response.data.message?error.response.data.message:'Please Verify your Details Once'}); 
            commit("setSmtpStatus", false);
        }
    },
    getSMTP:async({commit})=>{
        try {
            commit("setError", {});
            commit("setSmtpConfiguration", {});
            const response = await axios.get("/config/smtp");
            commit("setSmtpConfiguration", response.data.data);
        } catch (err) {
            console.log(err);
            commit("setError", {});
            commit("setSmtpStatus", false);
        }
    },

    getFilterById: async ({ commit }, id) => {
        try {
            commit("setSingleFilterData", null);
            const response = await axios.get(`entity-filter/${id}`);
            commit("setSingleFilterData", response.data.data);
        } catch (err) {
            commit("setSingleFilterData", null);
        }
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};