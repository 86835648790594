<template>
    <div class="internet-error">
      <div class="content" ref="contentRef" :class="{ 'breathing-animation': isBreathingAnimation }">
        <h1>Check Your Internet Connection</h1>
        <p>Please make sure you're connected to the internet and try again.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isBreathingAnimation: false
      };
    },
    mounted() {
      this.animationInterval = setInterval(this.toggleBreathingAnimation, 2000); // 2 seconds (animation + pause)
    },
    beforeDestroy() {
      clearInterval(this.animationInterval);
    },
    methods: {
      toggleBreathingAnimation() {
        this.isBreathingAnimation = !this.isBreathingAnimation;
      },
    },
  };
  </script>
  
  <style scoped>
  .internet-error {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    background-color: #f8f8f8;
  }
  
  .content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 150px; /* Add margin to create a gap between top and container */
  }
  
  /* Define the breathing animation */
  @keyframes breathe {
    0%, 100% {
      transform: translateZ(0) scale(1);
    }
    50% {
      transform: translateZ(-50px) scale(0.9);
    }
  }
  
  /* Apply the breathing animation */
  .breathing-animation {
    animation-name: breathe;
  }
  
  h1 {
    font-size: 24px;
    color: #1B487E;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 0;
  }
  </style>
  