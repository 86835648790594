import Dexie from "dexie";
import config from "@/config/app";

export const db = new Dexie(config.APP_NAME + "_" + config.APP_VERSION);

db.version(2).stores({
  templates:
    "++id, &_id, name, updated_at, createdAt, slug, type, created_at, templateGroups",
  entity:
    "++id, &_id, name, entity_type, createdAt, slug, type, updated_at, created_at",
  global_variables:
    "++id, &_id, label, createdAt, input_type, is_fixed, updated_at, created_at",
  formbuilders_details: "++id, &_id, name, createdAt, updated_at, created_at",
  entity_view_configuration:
    "++id, &_id, name, createdAt, updated_at, entity_id, created_at",
  customDashboard:
    "++id, &_id, name, updated_at, createdAt, *assigned_to, created_at",
  filters:
    "++id, &_id, filter_name, updated_at, createdAt, entity_id, created_at",
  entity_groups:
    "++id, &_id, name, updated_at, created_at, createdAt, docTemplates, formTemplates, globalVariables, workflows",
  entity_customization:
    "++id, &_id, label, updated_at, created_at, createdAt, entity_id, isActive",
  icons: "++id, &_id, name, content, createdAt"
});
