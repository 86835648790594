import axios from "@/config/axios";

const initialState = () => {
  return {
    createNewFilter: null,
    deleteFilterStatus: false,
    updateFilterStatus: false,
    updateFilterData: null,
    allEntityFilters: null,
    singleFilterData: null,
    filtersList:[],
    deleteFilterErros: null,
    allFilters: [],
    filtersOfAllEntities : null
  };
};
const state = initialState();

const getters = {
  getCreateNewFilter: (state) => state.createNewFilter,
  getDeleteFilterStatus: (state) => state.deleteFilterStatus,
  getUpdateFilterStatus: (state) => state.updateFilterStatus,
  getUpdateFilterData: state => state.updateFilterData,
  getAllEntityFilters: (state) => state.allEntityFilters,
  getSingleFilterData: (state) => state.singleFilterData,
  getFiltersList: (state)=>state.filtersList,
  getDeleteFilterErros: (state) => state.deleteFilterErros,
  getAllFilters: (state) => state.allFilters,
  getFiltersOfAllEntities : (state) => state.filtersOfAllEntities,

};
const mutations = {
  setCreateNewFilter: (state, data) => {
    state.createNewFilter = data;
  },
  setDeleteFilterStatus: (state, status) => {
    state.deleteFilterStatus = status;
  },
  setUpdateFilterData: (state, data) => {
    state.updateFilterData = data;
  },
  setUpdateFilterStatus: (state, status) => {
    state.updateFilterStatus = status
  },
  setAllEntityFilters: (state, data) => {
    state.allEntityFilters = data
  },
  setSingleFilterData: (state, data) => {
    state.singleFilterData = data
  },
  setFiltersList:(state,data)=>{
    state.filtersList=data
  },
  setDeleteFilterErros:(state, error) => {
    state.deleteFilterErros = error
  },
  setAllFilters:(state, data) => {
    state.allFilters = data
  },
  setFiltersOfAllEntities: (state, data) => {
    state.filtersOfAllEntities = data
  }
};
const actions = {
  fetchCreateNewFilter: async ({ commit }, params) => {
    try {
      commit("setCreateNewFilter", null);
      const response = await axios.post("entity-filter", params);
      commit("setCreateNewFilter", response.data.data);
    } catch (err) {
      commit("setCreateNewFilter", null);
    }
  },
  getFilterById: async ({ commit }, id) => {
    try {
      commit("setSingleFilterData", null);
      const response = await axios.get(`entity-filter/${id}`);
      commit("setSingleFilterData", response.data.data);
    } catch (err) {
      commit("setSingleFilterData", null);
    }
  },
  fetchAllEntityFilters: async ({ commit }, params) => {
    try {
      commit("setAllEntityFilters", null);
      const response = await axios.get(`entity-filter`, {params});
      commit("setAllEntityFilters", response.data.data);
    } catch (err) {
      commit("setAllEntityFilters", null);
    }
  },
  updateFilter: async ({ commit }, data) => {
    try {
      commit("setUpdateFilterStatus", null);
      commit("setUpdateFilterData", null);
      const response = await axios.post(`entity-filter/${data.id}`, data.params);
      commit("setUpdateFilterData", response.data);
      commit("setUpdateFilterStatus", true);
    } catch (err) {
      commit("setUpdateFilterStatus", null);
    }
  },
  deleteFilter: async ({ commit }, id) => {
    try {
      commit("setDeleteFilterStatus", false);
      commit("setDeleteFilterErros", null);
      await axios.delete(`entity-filter/${id}`);
      commit("setDeleteFilterStatus", true);
    } catch (err) {
      commit("setDeleteFilterErros", err.response.data);
      commit("setDeleteFilterStatus", false);
    }
  },
  fetchFiltersList: async ({ commit }, params) => {
    try {
      commit("setFiltersList", []);
      const response = await axios.get(`filters`, {params});
      commit("setFiltersList", response.data.data);
    } catch (err) {
      commit("setFiltersList", []);
    }
  },

  fetchFiltersListByIds: async ({ commit }, params) => {
    try {
      commit("setAllFilters", []);
      const response = await axios.post(`filters/all-filters`, params);
      commit("setAllFilters", response.data.data);
    } catch (err) {
      commit("setAllFilters", []);
    }
  },

  fetchAllFiltersByEntityIds: async ({ commit }, params) => {
    try {
      commit("setFiltersOfAllEntities", []);
      const response = await axios.get(`filters/fetch-all-filters-by-entity-ids`, {params});
      commit("setFiltersOfAllEntities", response.data?.data);
    } catch (err) {
      commit("setFiltersOfAllEntities", []);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};