import axios from "@/config/axios";

const initialState = () => {
    return {
        allCustomizations : null,
        newlyCreatedCustomization : null,
        customizationDeleteStatus : null,
        updatedCustomizationError : null,
        customizationById : null,
        entityActiveCustomization : null,
    }
}
const state = initialState();

const getters = {
    getAllCustomizations : (state) => state.allCustomizations,
    getNewlyCreatedCustomization : (state) => state.newlyCreatedCustomization,
    getCustomizationDeleteStatus : (state) => state.customizationDeleteStatus,
    getUpdatedCustomizationStatus : (state) => state.updatedCustomizationStatus,
    getCustomizationById : (state) => state.customizationById,
    getEntityActiveCustomization : (state) => state.entityActiveCustomization,
}

const mutations = {
    setAllCustomizations : (state, data) => {
        state.allCustomizations = data;
    },
    setNewlyCreatedCustomization : (state, data) => {
        state.newlyCreatedCustomization = data
    },
    setCustomizationDeleteStatus : (state, data) => {
        state.customizationDeleteStatus = data
    },
    setUpdatedCustomizationStatus : (state, data) => {
        state.updatedCustomizationStatus = data
    },
    setCustomizationById : (state, data) => {
        state.customizationById = data
    },
    setEntityActiveCustomization : (state, data) => {
        state.entityActiveCustomization = data
    }
}

const actions = {
    async fetchAllEntityCustomizations(context, id){
        try{
            context.commit('entityCustomization/setAllCustomizations',null, {root : true});
            let response = await axios.get(`/entity-customization/entity_id/${id}`)
            context.commit('entityCustomization/setAllCustomizations',response.data, {root : true});
        }
        catch(err){
            console.log("fetchAllEntityCustomizations",err)
        }
    },
    async createNewCustomization(context, data){
        try{
            context.commit("entityCustomization/setNewlyCreatedCustomization",null,{root : true});
            let response = await axios.post("/entity-customization/create",data);
            context.commit("entityCustomization/setNewlyCreatedCustomization",response.data,{root : true})
        }
        catch(err){
            console.log("createNewCustomization",err)
        }
    },
    async deleteCustomization(context, id){
        try{
            context.commit("entityCustomization/setCustomizationDeleteStatus",null,{root : true})
            let response = await axios.delete(`/entity-customization/${id}`);
            context.commit("entityCustomization/setCustomizationDeleteStatus",response.data,{root : true})
        }
        catch(err){
            console.log("deleteCustomization",err)
        }
    },
    async updateCustomization(context, data) {
        try{
            context.commit("entityCustomization/setUpdatedCustomizationStatus", null, {root : true});
            let response = await axios.put(`/entity-customization/update`,data);
            context.commit("entityCustomization/setUpdatedCustomizationStatus",response.data,{root : true})
        }
        catch(err){
            console.log("updateCustomization",err)
        }
    },
    async fetchCustomizationById(context, id){
        try{
            context.commit("entityCustomization/setCustomizationById",null,{root : true})
            let response = await axios.get(`/entity-customization/${id}`)
            context.commit("entityCustomization/setCustomizationById",response.data,{root : true})
        }
        catch(err){
            console.log("fetchCustomizationById",err)
        }
    },
    async fetchEntityActiveCustomization(context, id){
        try{
            context.commit("entityCustomization/setEntityActiveCustomization",null,{root : true})
            let response = await axios.get(`/entity-customization/entity/isActive/${id}`)
            context.commit("entityCustomization/setEntityActiveCustomization",response.data, {root : true})
        }
        catch(err){
            console.log("fetchEntityActiveCustomization",err)
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  