import axios from "@/config/axios";

const initialState = () => {
  return {
    createPermissionStatus: false,
    entityPermission: null,
    updatePermissionStatus: false,
  };
};

const state = initialState();

const getters = {
  getEntityPermission: state => state.entityPermission,
  getCreatePermissionStatus: state => state.createPermissionStatus,
  getUpdatePermissionStatus: state => state.updatePermissionStatus
};


const mutations = {
  setEntityPermission: (state, data) => {
    state.entityPermission = data
  },
  setCreatePermissionStatus: (state, data) => {
    state.createPermissionStatus = data
  },
  setUpdatePermissionStatus: (state, data) => {
    state.updatePermissionStatus = data
  }
};

const actions = {
  createEntityPermissions: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsersPermission/setCreatePermissionStatus', null, {
        root: true
      })
      // console.log("params",params)
      const response = await axios.post('/application-user/permissions', params);
      console.log("response", response.data)
      commit('applicationUsersPermission/setCreatePermissionStatus', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsersPermission/setCreatePermissionStatus', null, {
        root: true
      });
    }
  },
  updateEntityPermissions: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsersPermission/setUpdatePermissionStatus', null, {
        root: true
      })
      // console.log("params",params)
      const response = await axios.put(`/application-user/permissions/${params.id}`, params);
      console.log("response", response.data)
      commit('applicationUsersPermission/setUpdatePermissionStatus', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsersPermission/setUpdatePermissionStatus', null, {
        root: true
      });
    }
  },
  fetchPermissionsEntityById: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsersPermission/setEntityPermission', null, {
        root: true
      })
      // console.log("params",params)
      const response = await axios.get('/application-user/permissions/entity/'+params.id);
      commit('applicationUsersPermission/setEntityPermission', response.data.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsersPermission/setEntityPermission', null, {
        root: true
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
