import AuthMiddleware from "../middleware/auth"

const PublicLayout = () => import ('@/views/WebIntegrations.vue');
const Dashboard = () => import('@/views/Dashboard');
const EntitiesList = () => import('@/components/entity/EntitiesList');
const ViewEntityData = () => import('@/components/entity/ViewEntityData');
const EntitiesViewsList = () => import('@/components/entity/EntityCalculationViews');
const EntitiesViewEdit = () => import('@/components/entity/EntityViewsEdit');
const EntityViews = () => import('@/components/entity/EntityViews');
const createEntityFromExcel = () => import('@/components/entity/createEntityFromExcel');
const AddEditEntity = () => import('@/components/entity/AddEditEntity');
const ViewEntity = () => import("@/components/entity/ViewEntity");
const EntityDetailedView = () => import('@/components/entity/EntityDetailedCardView.vue');
const UserTemplatesData = () => import('@/views/users/UserTemplatesData');
const EntityExecute = () => import("@/components/entity/entityExecute");
const EntityExecuteStep = () => import("@/components/entity/entityExecuteSteps");
const PreviewEntityData = () => import("@/components/entity/preview");
const PreviewEntityDataStep = () => import("@/components/entity/previewStep");
const customDashboardList = () => import('@/components/customDashboard/customDashboardList.vue');
const customEntityMention = () => import('@/components/widgets/entityMention/index.vue');
const Roles = () => import('@/components/Roles/Roles');
const RequestDocuments = () => import('@/components/requestDocuments/AllRequestDocuments');
const RequestDocumentsSelectCardType = () => import('@/components/requestDocuments/SelectCardType');
const RequestDocumentDraft = () => import('@/components/requestDocuments/RequestDocumentDraft');
const UploadRequestDocumentPreference = () => import('@/components/requestDocuments/UploadRequestDocumentPreference');
const GlobalVariablesList = () => import('../views/globalVariables/GlobalVariablesList');
const GlobalVariableAdd = () => import('../views/globalVariables/GlobalVariableAdd');
const MenuManagement = () => import('../views/menuManagement');
const iFrameSignin =() =>import ('../views/iFrameSignin.vue');
const EntityGroupsDashboard = () => import('@/components/Groups/EntityGroups');
const EntityGroupPreview = () => import('../views/entities/EntityGroupPreview');
const EntityGroupPreviewEntityData = () => import('../views/entities/EntityGroupPreviewEntityData');
const FormBuilderList = () => import('@/views/formBuilders/FormBuildersList');
const FormBuilderUserData = () => import('@/views/formBuilders/FormBuilderUserData');
const FormbuilderView = () => import('@/components/formBuilders/formbuilderView.vue');
const FormbuilderViewStep = () => import('@/components/formBuilders/formbuilderViewStep.vue');
const FormbuilderDataList = () => import('@/components/formBuilders/formbuilderTemplateDataList');
const ApprovalFormsView = () => import('@/components/formBuilders/approvalFormsView.vue');
const AllTemplates = () => import('@/components/templates/AllTemplates');
const WorkflowList =() =>import ('../components/workFlows/templateWorkflowList');
const WorkflowDataList =() =>import ('../components/workFlows/templateWorkflowDataList');
const Documents = () => import('@/components/companyDocuments/AllDocuments');
const SignDocuments = () => import('@/components/SignDocuments');
const UploadDocumentPreference = () => import('@/components/companyDocuments/UploadDocumentPreference');
const UploadTemplatePreference = () => import('@/components/companyDocuments/UploadTemplatePreference');
const Profile = () => import('@/views/account/Profile');
const PersonalInformation = () => import("@/components/account/PersonalInformation");
const Brandings = () => import("@/components/account/Branding");
const CompanyUsers = () => import("@/components/account/CompanyUsers");
const Signature = () => import("@/components/account/Signature");
const Initial = () => import("@/components/account/Initial");
const UpdatePassword = () => import("@/components/account/UpdatePassword");
const Permissions = () => import("@/components/account/Permissions");
const SubscriptionInformation = () => import("@/components/account/Subscriptions.vue");
const plansList = () => import("@/components/account/Plans.vue");
const StripePaymentStatus = () => import("@/components/payment/payment-status.vue");
const PaymentIntegrations = () => import("@/components/Configuration/Payment/PaymentIntegrations.vue");
const PaymentsCards = () => import("../components/Configuration/Payment/Payment.vue");
const paymentStatus = () => import("@/components/payment/payment.vue");
const ApplicationUserDashboards = () => import('@/components/entity/ApplicationUserCustomDashboardList.vue');
const PageCustomDashboard = () => import('@/components/applicationUsers/PageCustomDashboard');
const entityDashboardConfig = () => import('@/components/customDashboard/customDashboardConfig.vue');
const Profilesettings = () => import('@/views/account/Profilesettings');
const DocumentExpirationReminders = () => import("@/components/account/DocumentExpirationReminders");
const ApplicationSettings = () => import("@/components/account/ApplicationSettings");
const Notifications = () => import("@/components/account/Notifications");
const Statistics = () => import("@/components/account/dashboardStats");
const GlobalFilters = () => import("@/components/account/GlobalFilters");
const Branding = () => import("@/views/account/Branding");
const ApplicationList = () => import('@/components/application/applicationsList.vue');
const PublishedAppsList = () => import('@/components/application/publishedApps.vue');
const ApprovalAppsList = () => import('@/components/application/appApprovals.vue');
const UploadAppsSteps = () => import('./../components/application/uploadAppSteps.vue');
const InstalledAppList = () => import('@/components/application/installedApps.vue');
const InstallApp = () => import("./../components/application/employeeManagementPopUp.vue");
const ListAllSteps = () => import("./../components/application/step-container.vue");
const CreateApplicationFromExcel = () => import("./../components/application/createApplicationFromExcel.vue");
const Contacts = () => import('@/components/contacts/Contacts');
const AddContacts = () => import('@/components/contacts/AddContact');
const UserDocuments = () => import('@/components/contacts/UserDocuments');
const ContactTypes = () => import('@/components/contacts/ContactTypes');
const sendDocumentOnMail = () => import('@/components/companyDocuments/sendDocumentOnMail');
const sendTemplateOnMail = () => import('@/components/companyDocuments/sendTemplateOnMail');
const CompanyUserList = () => import('@/components/company/CompanyUsers');
const TemplateList = () => import('../views/templates/TemplatesList');
const NewEmailTemplate = () => import('@/components/emailTemplates/newEmailTemplate.vue');
const EmailTemplatesList = () => import('@/components/emailTemplates/emailTemplatesList.vue');
const NewEmailHeaderFooter = () => import('@/components/emailTemplates/newHeaderFooter.vue')

export default[
    {
        path: '/if/:ssoAuth',
        component: PublicLayout,
        children: [
            {
                path: '',
                component: Dashboard,
            },
            {
                path: '/if/:ssoAuth/dashboard',
                component: Dashboard,
            },
            {
                path: "/if/:ssoAuth/entity",
                name: "EntityList_External",
                component: EntitiesList,
                meta: {
                    title: 'Entity List'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id",
                name: "EntityData1_External",
                component: ViewEntityData,
                meta: {
                    title: 'View Entity Data'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views",
                name: "EntityViewsList_External",
                component: EntitiesViewsList,
                meta: {
                    title: 'Entity views list'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/add",
                name: "EntityViewAdd_External",
                component: EntitiesViewEdit,
                meta: {
                    title: 'Entity view add'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/edit/:entity_view_id",
                name: "EntityViewEdit_External",
                component: EntitiesViewEdit,
                meta: {
                    title: 'Entity view edit'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/view/:entity_view_id",
                name: "EntityViews_External",
                component: EntityViews,
                meta: {
                    title: 'Entity views'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/excel/add",
                name: "entity-from-excel_External",
                component: createEntityFromExcel,
                meta: {
                    title: 'Create Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/add",
                name: "Create Entity_External",
                component: AddEditEntity,
                meta: {
                    title: 'Create Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/edit",
                name: "Edit Entity1_External",
                component: AddEditEntity,
                meta: {
                    title: 'Edit Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/view",
                name: "ViewEntity_External",
                component: ViewEntity,
                meta: {
                    title: 'View Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id",
                name: "EntityData_External",
                component: ViewEntityData,
                meta: {
                    title: 'View Entity Data'
                }
            },
            {
                path: "/if/:ssoAuth/entity/view/:entity_id",
                name: "EntityDetailedView2_External",
                component: EntityDetailedView,
                meta: {
                    title: "Entity data view"
                }
            },
            {
                path: "/if/:ssoAuth/entity/edit/:entity_id",
                name: "EntityDetailedEdit2_External",
                component: EntityDetailedView,
                meta: {
                    title: "Entity data edit"
                }
            },
            {
                path: "/if/:ssoAuth/entity/:user_id/:template_code",
                name: "TemplatesData_External",
                component: UserTemplatesData,
                meta: {
                    title: 'TemplatesData'
                }
            },
            {
                path: "/if/:ssoAuth/entity-execute/:entity_id",
                name: "EntityExecute_External",
                component: EntityExecute,
                children: [{
                    path: '/entity-execute/:entity_id/:template_code',
                    name: "EntityExecuteStep_External",
                    component: EntityExecuteStep,
                }]
            },
            {
                path: "/if/:ssoAuth/entity_data_view/:entity_id",
                name: "PreviewEntityData_External",
                component: PreviewEntityData,
                children: [{
                    path: '/if/:ssoAuth/entity_data_view/:entity_id/:template_code',
                    name: 'PreviewEntityDataStep_External',
                    component: PreviewEntityDataStep
                }]
            },
            {
                path: "/if/:ssoAuth/entityData-Edit/:entity_id",
                name: "EntityDataEdit_External",
                component: EntityExecute,
                children: [{
                    path: '/if/:ssoAuth/entityData-Edit/:entity_id/:template_code',
                    name: "EntityDataEditStep_External",
                    component: EntityExecuteStep,
                }]
            },
              //custom dashboard
        {
            path: '/if/:ssoAuth/custom/dashboard-list',
            component: customDashboardList,
            name: 'CustomDashboardList_External'
        },
        {
            path: '/if/:ssoAuth/custom/CustomEntityMention',
            component: customEntityMention,
            name: 'CustomEntitySuggestion_External'
        },
        //Roles
        {
            path: "/if/:ssoAuth/roles",
            component: Roles,
            name: "Roles_External",
        },

        {
            path: "/if/:ssoAuth/request-documents/all/:card_type?",
            name: "RequestDocuments_External",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType_External",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/upload",
            name: "upload-request-documents__External",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/:request_document_id/edit",
            name: "request-documents-edit_External",
            component: RequestDocumentDraft,
            // beforeEnter: AuthMiddleware.user
            meta: {
                title: 'Request Documents Edit'
            }
        },
        //Global Variables
        {
            path: "/if/:ssoAuth/global-variables",
            component: GlobalVariablesList,
            name: "GlobalVariables_External",

        },
        {
            path: "/if/:ssoAuth/global-variables/add",
            component: GlobalVariableAdd,

        },
        {
            path: "/if/:ssoAuth/global-variables/view/:global_variable_id",
            component: GlobalVariableAdd,
            name: "UpdateGlobalVariable_External",

        },
        {
            path: "/if/:ssoAuth/menu-management",
            name: "menuManagement_External",
            component: MenuManagement,
        },
        {
            path: "/if/:ssoAuth/iFrameSignin",
            name: "iFrameSignin_External",
            component: iFrameSignin,
        },
        {
            path: "/if/:ssoAuth/company-entity-groups",
            name: "EntityGroupsDashboard_External",
            component: EntityGroupsDashboard,

        },
        {
            path: "/if/:ssoAuth/company-entity-groups/:group_id/preview",
            name: "EntityGroupPreview_External",
            component: EntityGroupPreview,
            beforeEnter: AuthMiddleware.user,
            children: [{
                path: ":entity_id",
                component: EntityGroupPreviewEntityData,
                name: "EntityGroupPreviewEntityData_External",
                beforeEnter: AuthMiddleware.user
            },
            ]
        },
        {
            path: '/if/:ssoAuth/formBuilders',
            name: 'FormBuilderList_External',
            component: FormBuilderList,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: '/if/:ssoAuth/fbd/:formbuilder_code',
            name: 'FormBuilderUserData_External',
            component: FormBuilderUserData,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/if/:ssoAuth/fbd/:formbuilder_code/data-list",
            name: "FormbuilderDataList_External",
            component: FormbuilderDataList,
            beforeEnter: AuthMiddleware.user,
        },
        {
            path: '/if/:ssoAuth/forms/approval-forms',
            name: 'approval-forms_External',
            component: ApprovalFormsView,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/if/:ssoAuth/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderViewSSO_External",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/if/:ssoAuth/fb/view/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderViewStepSSO_External",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            },
            ]
        }, {
            path: "/if/:ssoAuth/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderEditSSO_External",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/if/:ssoAuth/fb/edit/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderEditStepSSO_External",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            }
            ]
        },
        {
            path: "/if/:ssoAuth/templates",
            name: "Templates_External",
            component: AllTemplates,
            // beforeEnter: AuthMiddleware.user,
            meta: {
                title: 'Templates'
            }
        },
        {
            path: "/if/:ssoAuth/workflows",
            name: "Workflows_External",
            component: WorkflowList,
            meta: {
                title: 'Workflows'
            }
        },
        {
            path: "/if/:ssoAuth/workflow/:workflow_id",
            name: "Workflows-Data_External",
            component: WorkflowDataList,
            meta: {
                title: 'Workflow Data'
            }
        },
        {
            path: "/if/:ssoAuth/documents/all/:contact_id?",
            name: "Documents_External",
            component: Documents,
            meta: {
                title: 'Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/all/:card_type?",
            name: "RequestDocuments__External",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType__External",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/documents-signed",
            name: "SignDocuments_External",
            component: SignDocuments,
            meta: {
                title: 'Signed Documents'
            }
        },
        {
            path: "/if/:ssoAuth/documents/upload",
            name: "upload-documents_External",
            component: UploadDocumentPreference,
            meta: {
                title: 'Upload Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/upload",
            name: "upload-request-documents_External",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/templates/upload",
            name: "upload-templates_External",
            component: UploadTemplatePreference,
            meta: {
                title: 'Upload Templates'
            }
        },
        // Profile Routes
        {
            path: '/if/:ssoAuth/profile',
            name: "profile_External",
            component: Profile,
            children: [{
                path: '/if/:ssoAuth/profile/personal-information',
                name: 'personal-information_External',
                component: PersonalInformation,
                meta: {
                    title: 'Personal Information'
                },
                alias: '/profile',
            },
            {
                path: '/if/:ssoAuth/profile/change-password',
                name: 'change-password_External',
                component: UpdatePassword,
                meta: {
                    title: 'Change Password'
                }
            },
            {
                path: '/if/:ssoAuth/profile/manage-permissions',
                name: 'manage-permissions_External',
                component: Permissions,
                meta: {
                    title: 'Manage Permissions'
                }
            },
            {
                path: '/if/:ssoAuth/profile/signature',
                name: 'signature_External',
                component: Signature,
                meta: {
                    title: 'Signature'
                }
            },
            {
                path: '/if/:ssoAuth/profile/initial',
                name: 'initial_External',
                component: Initial,
                meta: {
                    title: 'Initial'
                }
            },
            {
                path: '/if/:ssoAuth/profile/subscription',
                name: 'subscription_External',
                component: SubscriptionInformation,
                // component:PaymentGateway,
                meta: {
                    title: 'Subscription'
                }
            },
            {
                path: '/if/:ssoAuth/profile/companyUsers',
                name: 'companyUsers_External',
                component: CompanyUsers,
                meta: {
                    title: 'Company Users'
                }
            },
            {
                path: '/if/:ssoAuth/profile/companyProfile',
                name: 'companyProfile_External',
                component: Brandings,
                meta: {
                    title: 'Company Profile'
                }
            },

            ]
        },

        {
            path: "/if/:ssoAuth/plans-list",
            name: "Plans_External",
            component: plansList,
            meta: {
                title: 'E-signs plans List'
            }
        },
        {
            path: '/if/:ssoAuth/payments-status',
            name: 'Stripe Payment Status_External',
            component: StripePaymentStatus,
            meta: {
                title: 'stripe payment info'
            }
        },
        {
            path: '/if/:ssoAuth/profile/paymentIntegration',
            name: 'pay-integrations_External',
            component: PaymentIntegrations,
            meta: {
                title: 'Subscription'
            }
            //PaymentIntegrations
        },
        {
            path: '/if/:ssoAuth/profile/paymentsCards',
            name: 'integrations_External',
            component: PaymentsCards,
            meta: {
                title: 'Subscription payment'
            }
        },
        {
            path: '/if/:ssoAuth/paymentStatus',
            name: 'PaymentStatus_External',
            component: paymentStatus,
            meta: {
                title: 'Payment Status Info'
            }
        },
        {
            path : '/if/:ssoAuth/ap-user/custom-dashboards/:entityId',
            name : 'application-user-dashboards_External',
            component : ApplicationUserDashboards
        },
        {
            path : '/if/:ssoAuth/page-dashboard/:dashboardId',
            name : 'page-dashbaord_External',
            component : PageCustomDashboard
        },
        {
            path: '/if/:ssoAuth/ap/custom/dashboard/:entityId',
            name: 'entity-custom-dashboard-config_External',
            component: entityDashboardConfig,
            beforeEnter: AuthMiddleware.user
        },
        // {
        //     path: '/entity-payment-status-update',
        //     name: 'commonComponentForPayment',
        //     component: commonComponentForPayment,
        //     meta: {
        //         title: " Payment Update"
        //     }
        // },

        //profilesettings
        {
            path: '/if/:ssoAuth/profilesettings',
            name: "profilesettings_External",
            component: Profilesettings,
            children: [

                {
                    path: '/if/:ssoAuth/profilesettings/document-expiration-reminders',
                    name: 'expiration-reminders_External',
                    component: DocumentExpirationReminders,
                    meta: {
                        title: 'Document Expiration & Reminders'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/application-settings',
                    name: 'application-settings_External',
                    component: ApplicationSettings,
                    meta: {
                        title: 'Settings'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/document-notifications',
                    name: 'document-notifications_External',
                    component: Notifications,
                    meta: {
                        title: 'Notifications'
                    }
                },

                {
                    path: '/if/:ssoAuth/profilesettings/initial',
                    name: 'profilesettingsInitial_External',
                    component: Initial,
                    meta: {
                        title: 'Profile settings Initial'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/dashboard-settings',
                    name: 'statistics_External',
                    component: Statistics,
                    meta: {
                        title: 'Dashboard Status'
                    },
                    alias: '/profilesettings',
                },



                {
                    path: '/if/:ssoAuth/profilesettings/global-filters',
                    name: 'global-filters_External',
                    component: GlobalFilters,
                    meta: {
                        title: 'Global Filters'
                    },

                },
            ]
        },

        {
            path: '/if/:ssoAuth/branding',
            name: "Branding_External",
            component: Branding,
            meta: {
                title: 'Branding'
            }
        },

        {
            path: "/if/:ssoAuth/apps",
            name: "application_External",
            component: ApplicationList,
            meta: {
                title: 'Applications List'
            }
        },
        {
            path: '/if/:ssoAuth/apps/published',
            name: 'PublishedAppsList_External',
            component: PublishedAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/approvals',
            name: 'ApprovalAppsList_External',
            component: ApprovalAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/installed',
            name: 'InstalledAppsList_External',
            component: InstalledAppList,
            meta: {
                title: 'Your installed apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/upload',
            name: 'UploadYourOwnApp_External',
            component: UploadAppsSteps,
            meta: {
                title: 'Upload your own app to marketplace'
            }
        },
        {
            path: 'apps/create-from-excel',
            name: "CreateYourAppFromExcel_External",
            component: CreateApplicationFromExcel,
            meta: {
                title: 'Create application from excel'
            }

        },
        {
            path: '/if/:ssoAuth/apps/install/info',
            name: 'InstallApplication_External',
            component: InstallApp,
            meta: {
                title: 'Install a application from Marketplace to your workspace'
            }
        },
        {
            path: '/if/:ssoAuth/apps/new/steps',
            name: 'CreateYourApplication_External',
            component: ListAllSteps,
            meta: {
                title: 'Create Your Application from scratch'
            }
        },





        // Conatct Routes
        {
            path: "/if/:ssoAuth/contacts",
            name: "contacts_External",
            component: Contacts,
            meta: {
                title: 'Contacts'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/documents/:contact_id",
            name: "user-contacts_External",
            component: UserDocuments,
            meta: {
                title: 'User Documents'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/add",
            name: "add-contact_External",
            component: AddContacts,
            meta: {
                title: 'Add Contact'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/edit/:contact_id",
            name: "edit-contact_External",
            component: AddContacts,
            meta: {
                title: 'Edit Contact'
            }
        },
        // Contact type 
        {
            path: "/if/:ssoAuth/contact-types",
            name: "contactTypes_External",
            component: ContactTypes,
            meta: {
                title: 'Contact Types'
            }
        },
        {
            path: "/if/:ssoAuth/signature/documents/:status",
            name: "signature-documents_External",
            component: Documents,
            meta: {
                title: 'Signature Documents'
            }
        },
        {
            path: "/if/:ssoAuth/send-document/mail/:id",
            name: "send-document-mail_External",
            component: sendDocumentOnMail,
        },
        {
            path: "/if/:ssoAuth/send-template/mail/:id/:document_id",
            name: "send-template-mail_External",
            component: sendTemplateOnMail,
        },
        {
            path: "/if/:ssoAuth/company/users",
            name: "Company Users_External",
            component: CompanyUserList,
            alias: '',
            meta: {
                title: 'Company Users'
            }
        },
        // template Routes



        {
            path: "/if/:ssoAuth/template",
            name: "FormTemplate_External",
            component: TemplateList,
            meta: {
                title: 'Create New Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template/create",
            name: "CreateEmailTemplate_External",
            component: NewEmailTemplate,
            meta: {
                title: 'Create New Email Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template/edit/:template_id",
            name: "EditEmailTemplate_External",
            component: NewEmailTemplate,
            meta: {
                title: 'Edit Email Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-header-footer-template/create",
            name: "CreateEmailHeaderFooterTemplate_External",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Create New Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-header-footer-template/edit/:template_id",
            name: "EditEmailHeaderFooterTemplate_External",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Edit Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template",
            name: "EmailTemplatesList_External",
            component: EmailTemplatesList,
            meta: {
                title: 'Email Templates List'
            },
            alias: '',
        },
        ],
    },
]