import axios from "@/config/axios";
// initial state
const state = {
  loginResponse: null,
  tokens: null,
  tokensResponse: null,
  verifierToken:null,
  webhookData:null,
  fetchWebhook:null,
  clientData:null,
  allClientsData:null,
  clientAppDataById:null,
  deleteClientAppById:null
};

const getters = {
  getLoginOauth: state => state.loginResponse,
  getTokens: state => state.tokens,
  getTokensResponse: state => state.tokensResponse,
  getVerifierToken:state => state.verifierToken,
  getWebhookData:state => state.webhookData,
  getFetchWebhook:state => state.fetchWebhook,
  getclientData:state => state.clientData,
  getAllClientsData:state => state.allClientsData,
  getClientAppDataById:state => state.clientAppDataById,
  getDeleteClientAppById:state => state.deleteClientAppById
};

const mutations = {
  setIsOpen(state, isOpen) {
    state.isOpen = isOpen;
  },
  setLoginOauth(state, data) {
    state.loginResponse = data
  },
  setgetTokens(state, data) {
    state.tokens = data
  },
  setTokenss(state, data) {
    state.tokensResponse = data
  },
  setVerifierToken(state,data){
    state.verifierToken=data;
  },
  setWebhookData(state,data){
    state.webhookData=data
  },
  setFetchWebhook(state,data){
    state.fetchWebhook=data;
  },
  setclientData(state,data){
    state.clientData=data
  },
  setAllClientsData(state,data){
    state.allClientsData=data;
  },
  setClientAppDataById(state,data){
    state.clientAppDataById=data;
  },
  setDeleteClientAppById(state,data){
    state.deleteClientAppById=data;
  }
};

const actions = {
  loginOauth: async ({ commit }, userInfo) => {
    try {
      let response = await axios.post('/oauth2/signin', userInfo)
      commit("setLoginOauth", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  getTokenss: async ({ commit }, queryParams) => {
    try {
      let response = await axios.get(`/oauth2/authorize`, { params: queryParams })
      commit("setgetTokens", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  fetchVerifierToken:async ({commit}) =>{
    try {
      let response = await axios.get(`/oauth2/generate-verifier-token`)
      commit("setVerifierToken", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  saveWebhookData:async ({commit},params) =>{
    try {
      let response = await axios.post(`/oauth2/save-webhook`,params)
      commit("setWebhookData", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  fetchWebhookBycompanyId :async ({commit},params) =>{
    try {
      let response = await axios.post(`/oauth2/fetch-webhook`,params)
      commit("setFetchWebhook", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  async sendTokenInfo({ commit }, params) {
    try {
      let response = await axios.post(`http://localhost:3007/api/quickbooks/update-app-info`, params)
      commit("setTokenss", response.data);
    } catch (error) {
      console.log(error)
    }
  },
  async createClientData({commit},params){
    try{
      commit("setclientData",null);
      const response = await axios.post('generate-keys',params);
      commit("setclientData", response.data);
    }catch(err){
      console.log(err);
    }
  },
  async fetchAllClientApps({commit}){
    try{
      commit('setAllClientsData', null);
      const response = await axios.get('fetch-clients');
      commit('setAllClientsData', response.data);
    }catch(err){
      console.log(err)
    }
  },
  async fetchClientAppById({commit},params){
    commit("setClientAppDataById",null);
    const response = await axios.get(`fetch-client/${params.appId}`);
    commit("setClientAppDataById", response.data);
  },
  async deleteClientApp({commit},params){
    commit("setDeleteClientAppById",null);
    const response = await axios.delete(`delete-client/${params.appId}`);
    commit("setDeleteClientAppById", response.data);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};