var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"dialog-unroll"}},[(_vm.displaySync)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog-container",style:({
        height: _vm.containerHeight,
        width: _vm.containerWidth,
        maxHeight: _vm.containerMaxHeight,
        maxWidth: _vm.containerMaxWidth,
        minHeight: _vm.containerMinHeight,
        minWidth: _vm.containerMinWidth,
      }),attrs:{"prompt":_vm.warningmsg}},[(_vm.isShowHeader)?_c('div',{staticClass:"dialog-header",style:(_vm.headerStyle)},[_c('h2',{staticClass:"dialog-label",style:(_vm.labelStyle)},[_vm._v(_vm._s(_vm.title))]),(_vm.showClose)?_c('button',{staticClass:"close-button",style:(_vm.labelStyle),on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"scrollable-content"},[(_vm.showClose && !_vm.isShowHeader)?_c('button',{staticClass:"close-button",staticStyle:{"float":"right"},style:(_vm.labelStyle),on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e(),_vm._t("default")],2)]),(_vm.isShowFooter)?_c('div',{staticClass:"dialog-footer"},[_vm._t("footer")],2):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }