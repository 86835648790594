import axios from "@/config/axios";
import appConfig from "@/config/app";

const initialState = () => {
    return {
        plansData:null,
        activePaymentSystems:[],
        initStatus:{},
        updateSystemInfo:{},
        payment:{},
        orderInfo:{},
        paymentsHistory:{},
        paymentStatus:{},
        integratedList:[],
        plansFeatures:[],
        pgAccountDetails:{},
        updateEntityPayment:{},
        updateSystemInfoByTxnId:{},
    }
}

const state = initialState();

const getters = {
    getPlansData : state => state.plansData,
    getActivePaymentSystems: state => state.activePaymentSystems,
    getPaymentGatewayInfo : state => state.initStatus,
    getUpdateSystemInfo : state => state.updateSystemInfo,
    getUpdateSystemInfoByTxnId:state =>state.updateSystemInfoByTxnId,
    getPayment : state => state.payment,
    getOrderInfo : state => state.orderInfo,
    getPaymentsHistory :state => state.paymentsHistory,
    getPaymentStatus: state => state.paymentStatus,
    getIntegratedList:state => state.integratedList,
    getPlansFeatures:state =>state.plansFeatures,
    getPGDetails:state=>state.pgAccountDetails,
    getEntityPaymentStatus:state=>state.updateEntityPayment
  }

const mutations = {
    setPlansData(state,data){
        state.plansData = data
      },
    setOrderInfo(state,data){
        state.orderInfo = data
    },
    setActivePaymentSystems(state,data){
        state.activePaymentSystems=data;
      },
    setPaymentGatewayInfo(state,data){
      state.initStatus=data
    },
    setUpdateSystemInfo(state,data){
      state.updateSystemInfo=data
    },
    setUpdateSystemInfoByTxnId(state,data){
      state.updateSystemInfoByTxnId=data
    },
    setPayment(state,data){
      state.payment=data;
    },
    setPaymentsHistory(state,data){
      state.paymentsHistory=data;
    },
    setPaymentStatus(state,data)
    {
      state.paymentStatus=data;
    },
    setIntegratedList(state,data){
      state.integratedList=data
    },
    setPlansFeatures(state,data)
    {
      state.plansFeatures=data
    },
    setPGDetails(state,data)
    {
      state.pgAccountDetails=data;
    },
    setEntityPayment(state,data)
    {
      state.updateEntityPayment=data
    },

    reset: function (context) {
        context.commit("paymentGateway/resetData", null, {
          root: true
        });
    },
}

const actions = {
    reset: function (context) {
        context.commit("paymentGateway/resetData", null, {
          root: true
        });
      },
      fetchPlansData: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPlansData", null, {
            root: true
          })
          commit("paymentGateway/setPlansData", null, {
            root: true
          });
          // let response = await axios.get(`/esigns-plans`);
          // console.log(params)
          let response = await axios.get(`/getPlans/${appConfig.ESIGNS_PRODUCT_ID}?planType=${params.planType}&product=${params.productId}`);
          commit("paymentGateway/setPlansData", response.data.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPlansData", null, {
            root: true
          });
        }
      },

      fetchActivePaymentSystems: async({
        commit
      }) => {
        try {
          commit("paymentGateway/setActivePaymentSystems", [], {
            root: true
          })
         const response = await fetch(appConfig.PAYMENT_GATEWAY_URL+'/activeSystems',{
              method: 'GET',
            });
          const data = await response.json();
          console.log("active systems",data)
          commit("paymentGateway/setActivePaymentSystems", data.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setActivePaymentSystems", [], {
            root: true
          });
        }
      },

      fetchPaymentInit: async({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPaymentGatewayInfo", {}, {
            root: true
          })
          // const headers = { "Content-Type": "application/json" ,"Origin":"https://esigns.io","access-control-allow-origin":"https://esigns.io"};

          let headers = new Headers();

          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");
          const response = await fetch(appConfig.PAYMENT_GATEWAY_URL+'/init',{
              method: 'POST',
              headers: headers,
              body:JSON.stringify(params)
            });
          const data = await response.json();
          console.log("active systems",data)
          commit("paymentGateway/setPaymentGatewayInfo", data.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPaymentGatewayInfo", {}, {
            root: true
          });
        }
      },
      updateSystemInfoById:async({commit},params) => {
        try {
          console.log("params",params)
          commit("paymentGateway/setUpdateSystemInfo", {}, {
            root: true
          })
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");
          const response = await fetch(appConfig.PAYMENT_GATEWAY_URL+'/updateSystem/'+params.accountId,{
              method: 'PUT',
              headers: headers,
              body:JSON.stringify({
                data:params.data
              })
            });
            const data = await response.json();
            console.log("active systems",data)
            if(data.status)
            {
          //Insert payment Infomation into our system through api call start 
          const ourAppResponse = await axios.post('/integration', params.info)
          // end
          
          commit("paymentGateway/setUpdateSystemInfo", ourAppResponse.data.data, {
            root: true
          });
        }
        else{
          commit("paymentGateway/setUpdateSystemInfo", {}, {
            root: true
          });
          throw "External api error";
        }

        } catch (error) {
          commit("paymentGateway/setUpdateSystemInfo", {}, {
            root: true
          });
        }
      },

      updateInfoByTxnId:async({commit},params) => {
        try {
          commit("paymentGateway/setUpdateSystemInfoByTxnId", {}, {
            root: true
          })
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");
          const response = await fetch(appConfig.PAYMENT_GATEWAY_URL+'/update/'+params.accountId+'/'+params.txnId,{
              method: 'PUT',
              headers: headers,
              body:JSON.stringify(params.data)
            });
            const data = await response.json();
            console.log("active systems",data)
            // if(data.status)
            // {
          
          commit("paymentGateway/setUpdateSystemInfoByTxnId", data, {
            root: true
          });
        // }
        // else{
        //   commit("paymentGateway/setUpdateSystemInfoByTxnId", {}, {
        //     root: true
        //   });
        //   throw "External api error";
        // }

        } catch (error) {
          commit("paymentGateway/setUpdateSystemInfoByTxnId", {}, {
            root: true
          });
        }
      },
      paymentCapture: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPayment", [], {
            root: true
          })
          let response = await axios.post(`/payment`,params);

          commit("paymentGateway/setPayment", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPayment", {}, {
            root: true
          });
        }
      },
      placeOrder: async ({
        commit
      },params) => {
        try {
          
          commit("paymentGateway/setOrderInfo", null, {
            root: true
          });
          
          let response = await axios.post('/orderPlace', params);
          commit("paymentGateway/setOrderInfo", response.data, {
            root: true
          });
          console.log("setOrderInfo",response.data)
        } catch (error) {
          commit("paymentGateway/setOrderInfo", null, {
            root: true
          });
        }
      },

      subscriptionInfo:async ({commit},params)=>{
        try {
          
          commit("paymentGateway/setPaymentsHistory", {}, {
            root: true
          });
          commit("paymentGateway/setPlansFeatures", [], {
            root: true
          });
          
          let response = await axios.get('/getPlanInfo', params);
          commit("paymentGateway/setPaymentsHistory", response.data, {
            root: true
          });
          console.log("Subscriptions:",response.data)
          commit("paymentGateway/setPlansFeatures",  response.data.planFeatures, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPaymentsHistory", {}, {
            root: true
          });
          commit("paymentGateway/setPlansFeatures", [], {
            root: true
          });
        }
      },
      fetchSubscriptionInfo: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPaymentsHistory", {}, {
            root: true
          });
          let response = await axios.get('/getPlanInfo',params);
          commit("paymentGateway/setPaymentsHistory", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPaymentsHistory", {}, {
            root: true
          });
        }
      },
      paymentStatusStore: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPaymentStatus", {}, {
            root: true
          })
          let response = await axios.post(`/subscription`,params);

          commit("paymentGateway/setPaymentStatus", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPaymentStatus", {}, {
            root: true
          });
        }
      },
      fetchInitSystemsData: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setIntegratedList", [], {
            root: true
          })
          console.log(params)
          let ourResponse = await axios.get('/integration');
          let pg_account_info = ourResponse.data.data;
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");

          let response = await fetch(`${appConfig.PAYMENT_GATEWAY_URL}/getAccInfo/${pg_account_info.pg_account_id}`, {
            method: "get",
            headers: headers
          });
          response= await response.json();
          commit("paymentGateway/setIntegratedList", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setIntegratedList", [], {
            root: true
          });
        }
      },
      fetchInitSystemsDataByTemplate: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setIntegratedList", [], {
            root: true
          })
          console.log(params)
          let ourResponse = await axios.get('/integrationByTemplate/'+params);
          let pg_account_info = ourResponse.data.data;
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");

          let response = await fetch(`${appConfig.PAYMENT_GATEWAY_URL}/getAccInfo/${pg_account_info.pg_account_id}`, {
            method: "get",
            headers: headers
          });
          response= await response.json();
          commit("paymentGateway/setIntegratedList", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setIntegratedList", [], {
            root: true
          });
        }
      },

      fetchInitSystemsDataByPgId: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setPGDetails", {}, {
            root: true
          })
          // let response = await axios.get('/integration/'+params);
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Accept', 'application/json');
          headers.append('Origin','https://esigns.io');
          headers.append('access-control-allow-origin',"https://esigns.io");
          
          let response = await fetch(`${appConfig.PAYMENT_GATEWAY_URL}/getAccInfo/${params}`, {
            method: "get",
            headers: headers
          });
          response= await response.json();
          commit("paymentGateway/setPGDetails", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setPGDetails", {}, {
            root: true
          });
        }
      },

      entityPaymentCapture: async ({
        commit
      },params) => {
        try {
          commit("paymentGateway/setEntityPayment", {}, {
            root: true
          })
          let response = await axios.post(`/updateRulesOfPayBtn`,params);

          commit("paymentGateway/setEntityPayment", response.data, {
            root: true
          });
        } catch (error) {
          commit("paymentGateway/setEntityPayment", {}, {
            root: true
          });
        }
      },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };