import io from "socket.io-client";
import store from "@/store";
let apiUrl =
  process.env.VUE_APP_STAGE == "DEV"
    ? process.env.VUE_APP_DEV_API_URL
    : process.env.VUE_APP_LOCAL_API_URL;
let companyDetails =
    store.getters["company/getCompanyDetails"] ||
    store.getters["company/getContactLoginSlug"],
  headers = {
    extraHeaders: {
      workspaceid: "",
    },
  };
if (companyDetails?._id) {
  headers.extraHeaders.workspaceid = companyDetails._id;
}
apiUrl = apiUrl.replace("/v1.0", "");
const socket = io(apiUrl, headers);

export default {
  install(Vue) {
    Vue.prototype.$socket = socket;
  },
};
