import axios from "@/config/axios";

// initial state
const initialState = () => {
    return {
        codeExchangeStatus: false,
        codeExchangeData: {},
        ssoNewUserData: {},
        ssoExistedUserData: {},
        authenticatedSSOToken:null,
        isLogin: false
    };
};

const state = initialState();

const getters = {
    getCodeExchangeStatus: state => state.codeExchangeStatus,
    getCodeExchangeData: state => state.codeExchangeData,
    getSSONewUserData: state => state.ssoNewUserData,
    getSSOExistedUserData: state => state.ssoExistedUserData,
    getAuthenticatedSSOToken : state => state.authenticatedSSOToken,
    getIsUserLoggedIn: state =>  state.isLogin,
      
};

const mutations = {
    setCodeExchangeStatus: (state, data) => {
        state.codeExchangeStatus = data
    },
    setCodeExchangeData: (state, data) => {
        state.codeExchangeData = data
    },
    setSSONewUserData: (state, data) => {
        state.ssoNewUserData = data
    },
    setSSOExistedUserData: (state, data) => {
        state.ssoExistedUserData = data
    },
    setAuthenticatedSSOToken:(state, data) =>{
        state.authenticatedSSOToken = data
    },

    resetAuthData: state => {
        const initial = initialState();
        Object.keys(initial).forEach(key => {
            state[key] = initial[key];
        });
    },
    setIsLogin:(state, data) => {
        state.isLogin = data
      }
};

const actions = {
    changeFlag: async(context, value)=>{

        context.commit("sso/setIsLogin", value, {
            root: true
        });
    },
   
    signIn: async (context) => {
        context.commit("sso/resetAuthData", null, {
            root: true
        });

    },
    reset: function (context) {
        context.commit("auth/resetAuthData", null, {
            root: true
        });
    },
    fetchTokenFromCode: async (context, params) => {
        try {
            context.commit("sso/setCodeExchangeStatus", null, {
                root: true
            });
            const response = await axios.post('/sso/token', params)

            context.commit("sso/setCodeExchangeStatus", true, {
                root: true
            });

            context.commit("sso/setCodeExchangeData", response.data, {
                root: true
            });
            if (response.data.status_code == "EXISTED_USER_LOGIN_SUCCESS") { // we need to got to log in page
                let userDetails = response.data.user_details;
                let tokens = {
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    refresh_token_expires_at: response.data.refresh_token_expires_at,
                    access_token_expires_at: response.data.access_token_expires_at  
                };
                context.commit("sso/setAuthenticatedSSOToken",params.code,{root:true});
                
                context.commit("auth/setAuthenticatedUser", userDetails, {
                    root: true
                });

                context.commit("auth/setAuthenticationDetails", tokens, {
                    root: true
                });

                context.commit("auth/setAuthenticationStatus", true, {
                    root: true
                });
                context.commit("auth/setErrorCode", "", {
                    root: true
                });
                context.commit("auth/setErrorData", "", {
                    root: true
                });
            }
            else {
                if (response.data.status_code == "NEW_USER") {
                    // we need ask user to signup page
                    context.commit("sso/setSSONewUserData", response.data.data, {
                        root: true
                    });
                }
                else if (response.data.error_code == "HOLD_WORKSPACE") {
                    // we need ask user to signup page
                    context.commit("sso/setSSOInactiveUser", response.data.data, {
                        root: true
                    });


                }
            }
        } catch (error) {
            context.commit("sso/setCodeExchangeStatus", false, {
                root: true
            });

            await context.dispatch("sso/errorResponse", error.response, {
                root: true
            });
            context.commit("sso/setCodeExchangeData", null, {
                root: true
            });


            if (error.response) {

                console.log(error.response)

                if (error.response.data.errorCode === "EMAIL_NOT_VERIFIED") {

                    context.commit("auth/setAccountNotVerified", true, {
                        root: true
                    })

                    context.commit("auth/setAccountNotVerifiedEmail", error.response.data.email, {
                        root: true
                    })
                    await context.dispatch('errors/errorResponse', error.response, {
                        root: true
                    });
                }

                else if (error.response.data.error_code === "HOLD_WORKSPACE") {

                    context.commit("auth/setErrorCode", "HOLD_WORKSPACE", {
                        root: true
                    });

                    context.commit("sso/setErrorCode", "HOLD_WORKSPACE", {
                        root: true
                    });

                    context.commit("sso/setSSOExistedUserData", error.response.data.data, {
                        root: true
                    });




                } else {
                    await context.dispatch('errors/errorResponse', error.response, {
                        root: true
                    });
                }
                context.commit("auth/setErrorCode", error.response.data.errorCode || error.response.data.error_code, {
                    root: true
                });
                context.commit("auth/setErrorData", error.response.data.errorData, {
                    root: true
                });
            }
        }
    },



    errorResponse: async (context, errorResponse) => {
        let errorStatusCode = errorResponse.status;

        let errorData = [];

        switch (errorStatusCode) {
            case 422:
                var errors = errorResponse.data.errors;

                errors.details.forEach(detail => {
                    if (!errorData[detail.key]) {
                        errorData[detail.key] = detail.message;
                    }
                });

                break;

            case 401:
            case 403:
                errorData["critical_error"] = errorResponse.data.message;

                break;

            default:
                break;
        }

        return errorData;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};