import axios from "@/config/axios";

const initialState = () => {
    return {
        allCompanyEmailTemplatesData: {},
        entityEmailTemplatesData: {},
        emailTemplateError: "",
        deleteEmailTemplateStatus: false,
        createdEmailTemplateData: false,
        updatedEmailTemplateData: false,
        duplicateTemplateStatus: false,
        currentTemplateData: {},
        emailLogs : {}
    }
}

const state = initialState();

const getters = {
    getAllCompanyEmailTemplatesData: state => state.allCompanyEmailTemplatesData,
    getEmailTemplateError: state => state.emailTemplateError,
    getDeleteEmailTemplateStatus: state => state.deleteEmailTemplateStatus,
    getCreatedEmailTemplateStatus: state => state.createdEmailTemplateData,
    getUpdatedEmailTemplateStatus: state => state.updatedEmailTemplateData,
    getDuplicateTemplateStatus: state => state.duplicateTemplateStatus,
    getCurrentEmailTemplateData: state => state.currentTemplateData,
    getEntityEmailTemplatesData: state => state.entityEmailTemplatesData,
    getEmailLogs: state => state.emailLogs
}

const mutations = {
    setAllCompanyEmailTemplatesData(state, data) {
        state.allCompanyEmailTemplatesData = data
    },
    setEntityEmailTemplatesData(state, data) {
        state.entityEmailTemplatesData = data
    },
    setEmailTemplateError(state, data) {
        state.emailTemplateError = data
    },
    setDeleteEmailTemplateStatus(state, data) {
        state.deleteEmailTemplateStatus = data
    },
    setCreatedEmailTemplateData(state, data) {
        state.createdEmailTemplateData = data
    },
    setDuplicateTemplateStatus(state, data) {
        state.duplicateTemplateStatus = data
    },
    setCurrentEmailTemplateData(state, data) {
        state.currentTemplateData = data
    },
    setUpdatedEmailTemplateData(state, data) {
        state.updatedEmailTemplateData = data
    },
    setEmailLogs(state, data){
        state.emailLogs = data
    },
}

const actions = {
    async allEmailTemplates(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setAllCompanyEmailTemplatesData", {
                data: null
            }, {
                root: true
            })
            let response = await axios.get('email-template/list', {
                params
            });
            context.commit("entityEmailTemplate/setAllCompanyEmailTemplatesData", response.data, {
                root: true
            })
        } catch (error) {
            context.commit("entityEmailTemplate/setAllCompanyEmailTemplatesData", {
                data: null
            }, {
                root: true
            })
            context.commit("entityEmailTemplate/setEmailTemplateError", error.response.data.message, {
                root: true
            })
        }
    },
    async entityEmailTemplates(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setEntityEmailTemplatesData", {
                data: null
            }, {
                root: true
            })
            let response = await axios.get(`entity-email-template/getAll/${params.entity_id}`, {
                params
            });
            context.commit("entityEmailTemplate/setEntityEmailTemplatesData", response.data.data, {
                root: true
            })
        } catch (error) {
            context.commit("entityEmailTemplate/setEntityEmailTemplatesData", {
                data: null
            }, {
                root: true
            })
            context.commit("entityEmailTemplate/setEmailTemplateError", error.response.data.message, {
                root: true
            })
        }
    },
    async deleteEmailTemplate(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setDeleteEmailTemplateStatus", {
                data: false
            }, {
                root: true
            })
            let response = await axios.delete(`entity-email-template/delete/${params.id}`);
            context.commit("entityEmailTemplate/setDeleteEmailTemplateStatus", response.data.success, {
                root: true
            })
        } catch (error) {
            context.commit("entityEmailTemplate/setEmailTemplateError", {
                data: []
            }, {
                root: true
            })
        }
    },
    async createEmailTemplate(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setCreatedEmailTemplateData", false, {
                root: true
            })
            let response = await axios.post(`/entity-email-template/create`, params);
            context.commit("entityEmailTemplate/setCreatedEmailTemplateData", response.data.success, {
                root: true
            })
        } catch (err) {
            if (err.response && err.response.data) {
                // commit("setEntityErrors", err.response.data.message);
                context.commit("entityEmailTemplate/setCreatedEmailTemplateData", false, {
                    root: true
                })
                context.commit("entityEmailTemplate/setEmailTemplateError", err.response.data.message, {
                    root: true
                })

            }
            else {
                context.commit("entityEmailTemplate/setEmailTemplateError", "Something went wrong !", {
                    root: true
                })
            }
        }
    },
    async duplicateTemplate(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setDuplicateTemplateStatus", false, {
                root: true
            })
            let response = await axios.post(`/entity-email-template/duplicate`, params);
            context.commit("entityEmailTemplate/setDuplicateTemplateStatus", response.data.success, {
                root: true
            })
        } catch (err) {
            if (err.response && err.response.data) {
                // commit("setEntityErrors", err.response.data.message);
                context.commit("entityEmailTemplate/setDuplicateTemplateStatus", false, {
                    root: true
                })
                context.commit("entityEmailTemplate/setEmailTemplateError", err.response.data.message, {
                    root: true
                })

            }
            else {
                context.commit("entityEmailTemplate/setEmailTemplateError", "Something went wrong !", {
                    root: true
                })
            }
        }
    },
    async currentTemplateData(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setCurrentEmailTemplateData", {}, {
                root: true
            })
            let response = await axios.get(`/entity-email-template/${params.template_id}`);
            context.commit("entityEmailTemplate/setCurrentEmailTemplateData", response.data.data, {
                root: true
            })
        } catch (err) {
            if (err.response && err.response.data) {
                // commit("setEntityErrors", err.response.data.message);
                context.commit("entityEmailTemplate/setCurrentEmailTemplateData", {}, {
                    root: true
                })
                context.commit("entityEmailTemplate/setEmailTemplateError", err.response.data.message, {
                    root: true
                })

            }
            else {
                context.commit("entityEmailTemplate/setEmailTemplateError", "Something went wrong !", {
                    root: true
                })
            }
        }
    },
    async updateTemplateData(context, params = {}) {
        try {
            context.commit("entityEmailTemplate/setUpdatedEmailTemplateData", false, {
                root: true
            })
            let response = await axios.patch(`/entity-email-template/update`, params);
            context.commit("entityEmailTemplate/setUpdatedEmailTemplateData", response.data.success, {
                root: true
            })
        } catch (err) {
            if (err.response && err.response.data) {
                // commit("setEntityErrors", err.response.data.message);
                context.commit("entityEmailTemplate/setUpdatedEmailTemplateData", false, {
                    root: true
                })
                context.commit("entityEmailTemplate/setEmailTemplateError", err.response.data.message, {
                    root: true
                })

            }
            else {
                context.commit("entityEmailTemplate/setEmailTemplateError", "Something went wrong !", {
                    root: true
                })
            }
        }
    },
    async fetchEmailLogs(context, params){
        try{
            context.commit("entityEmailTemplate/setEmailLogs",false,{
                root : true
            })
            let response = await axios.post('/entities/emails',params)
            context.commit("entityEmailTemplate/setEmailLogs",response,{root : true})
        }
        catch(err){
            context.commit("entityEmailTemplate/setEmailLogs",err,{root : true})
        }
    }
}


export default {
    namespaced: true,
    mutations,
    getters,
    state,
    actions
}

